import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Basic/Button';
import { PanelElement } from '../style';
import { AppDispatch, RootState } from 'store';
import { suggestNewPanelAlignment } from 'store/slices/RoofDataSlice/roofDataSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { ToolNewPostionSilce } from 'store/slices/ToolSlice/types';

const RecommendPanelsButton = React.memo(() => {
	const dispatch = useDispatch<AppDispatch>();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();

	const { annualInputEnergy, selectedPanels: selectedPanelsOnUI } = useSelector(
		(state: RootState) => state.energyData.data as EnergyState
	);
	const { drawModeEnabled, editModeEnabled } = useSelector(
		(state: RootState) => state.toolNewPostions.data as ToolNewPostionSilce
	);
	const { recommendedPanels } = useSelector(
		(state: RootState) => state.roofData.data
	);

	const sortedSelectedPanelsOnUI = React.useMemo(() => [...selectedPanelsOnUI].sort(), [selectedPanelsOnUI]);
	const sortedSelectedPanels = React.useMemo(() => [...recommendedPanels ?? []].sort(), [recommendedPanels]);

	const isRecommendationNeeded = React.useMemo(
		() => !(
			sortedSelectedPanelsOnUI.length === sortedSelectedPanels.length &&
			sortedSelectedPanels.every((panel, index) => panel === sortedSelectedPanelsOnUI[index])
		),
		[sortedSelectedPanels, sortedSelectedPanelsOnUI]
	);

	// Memoize the recommendPanels function
	const recommendPanels = useCallback(async () => {
		await dispatch(suggestNewPanelAlignment({ energy: annualInputEnergy }));
	}, [dispatch, annualInputEnergy]);

	return (
		<PanelElement>
			<Button
				onClick={recommendPanels}
				disabled={queriesRunningInBackGround || drawModeEnabled || editModeEnabled || !isRecommendationNeeded}
			>
				Recommend panels
			</Button>
		</PanelElement>
	);
});

RecommendPanelsButton.displayName = 'RecommendPanelsButton';

export default RecommendPanelsButton;

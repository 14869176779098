/* eslint-disable max-len */
// TO_DO: dynamic options 

import Button from 'components/Basic/Button';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { toggleShadingModalState, toggleUndoShadingModalState } from 'store/slices/EditDeleteOptionsModalSlice';
import { EditToolOptionsWrap } from '../styles';

export default function EditToolOptions() {

	const { selectedRoofIndex, drawModeEnabled, newlyCreatedFacets, deletedRoofIndexes } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { canRemoveShading } = useSelector((state: RootState) => state.QueryParams.data);
	const dispatch = useDispatch<AppDispatch>();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const checkSelectedRoofIndexExists= selectedRoofIndex != null && !deletedRoofIndexes.includes(selectedRoofIndex);
	const disableDeleteBtn= (!drawModeEnabled && !checkSelectedRoofIndexExists) || (drawModeEnabled && !newlyCreatedFacets.length) || queriesRunningInBackGround || activePanelMode;
	const disableChangeOrientationBtn= (!drawModeEnabled && !checkSelectedRoofIndexExists) || (drawModeEnabled) || queriesRunningInBackGround || activePanelMode;
	const facet = allRoofSegs.find(rs=>rs.id===selectedRoofIndex);

	return (
		<EditToolOptionsWrap>
			<>
				<Button
					id="change-orientation"
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #C7CACF',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}} onClick={() => {
						dispatch(toggleOrientationModalState({ open: true, isLoading: false }));
					}}
					disabled={disableChangeOrientationBtn}
					
					tooltipContent='Rotation'
				>
					<svg width="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19.0729 8.25146C19.0729 4.97901 16.3954 2.00405 12.528 2.00405L14.3129 4.08652M1 11.8214C1 15.0939 3.67746 18.0688 7.54491 18.0688L5.75993 15.9864M6.94992 1L18.7754 12.8255L12.8255 18.7754L1 6.94992L6.94992 1Z" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					</svg>

				</Button>
			</>
			{!facet?.isFluxPatched && canRemoveShading && <>
				<Button
					id="remove-shading"
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #C7CACF',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}}
					disabled={!selectedRoofIndex || queriesRunningInBackGround || activePanelMode}
					onClick={() => {
						dispatch(toggleShadingModalState({ value: true }));
					}}
					tooltipContent='Remove Shading'
				>
					<svg width="19" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.0765 10.7488C16.7242 14.4197 12.6214 15.9312 10.786 13.6639C7.76285 16.2551 4.73975 13.5559 5.38763 10.7488C4.09657 9.35001 3.72097 7.62882 4.20015 6.32209C4.68263 5.00635 6.0299 4.10931 7.65496 3.9468C8.95067 1.57141 11.9738 1.13968 13.9171 3.9468C15.3444 3.8327 16.8853 5.05954 17.3722 6.64614C17.8061 8.06016 17.3485 9.78215 16.0765 10.7488Z" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M10.7854 6.53906V9.99403M10.7854 18.8474V12.2614M10.7854 9.99403L13.2687 8.48248M10.7854 9.99403V12.2614M10.7854 12.2614L8.41016 10.8578" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M19 18.5L1.5 1" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					</svg>

				</Button>
			</>}
			{facet?.isFluxPatched && canRemoveShading && <>
				<Button
					id="undo-remove-shading"
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #C7CACF',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}}
					disabled={!selectedRoofIndex || queriesRunningInBackGround}
					onClick={() => {
						dispatch(toggleUndoShadingModalState({ value: true }));
					}}
					tooltipContent='Undo Remove Shading'
				>
					<svg width="18" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.4029 10.3475C15.0949 14.2696 10.7114 15.8846 8.75042 13.4621C5.52035 16.2307 2.29037 13.3467 2.98259 10.3475C1.60317 8.853 1.20188 7.01402 1.71385 5.61786C2.22934 4.21209 3.66881 3.25365 5.40508 3.08003C6.78946 0.542078 10.0194 0.0808097 12.0958 3.08003C13.6207 2.95812 15.267 4.26891 15.7873 5.9641C16.2509 7.47488 15.762 9.31471 14.4029 10.3475Z" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M14.4029 10.3475C15.0949 14.2696 10.7114 15.8846 8.75042 13.4621C5.52035 16.2307 2.29037 13.3467 2.98259 10.3475C1.60317 8.853 1.20188 7.01402 1.71385 5.61786C2.22934 4.21209 3.66881 3.25365 5.40508 3.08003C6.78946 0.542078 10.0194 0.0808097 12.0958 3.08003C13.6207 2.95812 15.267 4.26891 15.7873 5.9641C16.2509 7.47488 15.762 9.31471 14.4029 10.3475Z" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M14.4029 10.3475C15.0949 14.2696 10.7114 15.8846 8.75042 13.4621C5.52035 16.2307 2.29037 13.3467 2.98259 10.3475C1.60317 8.853 1.20188 7.01402 1.71385 5.61786C2.22934 4.21209 3.66881 3.25365 5.40508 3.08003C6.78946 0.542078 10.0194 0.0808097 12.0958 3.08003C13.6207 2.95812 15.267 4.26891 15.7873 5.9641C16.2509 7.47488 15.762 9.31471 14.4029 10.3475Z" stroke="#28373E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M8.75073 5.84961V9.54102M8.75073 19.0002V11.9635M8.75073 9.54102L11.4039 7.92603M8.75073 9.54102V11.9635M8.75073 11.9635L6.21289 10.4639" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M8.75073 5.84961V9.54102M8.75073 19.0002V11.9635M8.75073 9.54102L11.4039 7.92603M8.75073 9.54102V11.9635M8.75073 11.9635L6.21289 10.4639" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M8.75073 5.84961V9.54102M8.75073 19.0002V11.9635M8.75073 9.54102L11.4039 7.92603M8.75073 9.54102V11.9635M8.75073 11.9635L6.21289 10.4639" stroke="#28373E" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					</svg>

				</Button>
			</>}
			<>
				<Button
					id="delete"
					title='Delete'
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '0',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}} onClick={() => {
						dispatch(toggleDeleteModalState({ value: true }));
					}}
					disabled={disableDeleteBtn}
					tooltipContent='Delete'>
					<svg width="16" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.93016 9.03239V15.0567M10.8836 9.03239V15.0567M1 5.01619H16.8138M15.8254 5.01619L14.9685 17.2074C14.933 17.714 14.7098 18.1881 14.344 18.5343C13.9781 18.8804 13.4967 19.0729 12.9967 19.0729H4.81705C4.31708 19.0729 3.83567 18.8804 3.4698 18.5343C3.10393 18.1881 2.88077 17.714 2.84527 17.2074L1.98836 5.01619H15.8254ZM11.872 5.01619V2.00405C11.872 1.73776 11.7678 1.48237 11.5825 1.29408C11.3971 1.10578 11.1457 1 10.8836 1H6.93016C6.66803 1 6.41664 1.10578 6.23129 1.29408C6.04593 1.48237 5.9418 1.73776 5.9418 2.00405V5.01619H11.872Z" stroke="#4A525A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					</svg>

				</Button>
			</>
		</EditToolOptionsWrap>

	);
}
/* eslint-disable max-len */
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { CloseBtn, DropdownDiv } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setDropdownState, toggleShadingModalState, toggleUndoShadingModalState } from 'store/slices/EditDeleteOptionsModalSlice';
import { Popup } from 'reactjs-popup';
import { setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import Button from 'components/Basic/Button';

interface Option {
	label: string;
	action: () => AnyAction;
	icon: JSX.Element;
}

export default function EditDeleteOptionsModal() {
	const { position, open } = useSelector(
		(state: RootState) => state.editDeleteOptionsModal.data
	);
	const { drawModeEnabled, selectedRoofIndex } = useSelector(
		(state: RootState) => state.toolNewPostions.data
	);

	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { canRemoveShading } = useSelector((state: RootState) => state.QueryParams.data);
	const facet = allRoofSegs.find(rs=>rs.id===selectedRoofIndex);
	const queriesRunningInBackGround = useQueriesRunningInBackGround();

	const dispatch = useDispatch();

	const Options: Option[] = [
		{
			label: 'Delete Facet',
			action: () => toggleDeleteModalState({ value: true }),
			icon: (
				<svg
					width='16'
					style={{ marginRight: '14px' }}
					viewBox='0 0 16 18'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M6.25 8.11111V13.4444M9.75 8.11111V13.4444M1 4.55556H15M14.125 
							4.55556L13.3664 15.3484C13.3349 15.797 13.1374 16.2167 12.8135 
							16.5232C12.4896 16.8296 12.0634 17 11.6208 17H4.37925C3.93662 
							17 3.51043 16.8296 3.18652 16.5232C2.86262 16.2167 2.66505 
							15.797 2.63363 15.3484L1.875 4.55556H14.125ZM10.625 
							4.55556V1.88889C10.625 1.65314 10.5328 1.42705 10.3687 
							1.26035C10.2046 1.09365 9.98206 1 9.75 1H6.25C6.01794 
							1 5.79538 1.09365 5.63128 1.26035C5.46719 1.42705 5.375 
							1.65314 5.375 1.88889V4.55556H10.625Z'
						stroke='#4A525A'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			),

		}
	];

	if (!drawModeEnabled) {
		Options.push({
			label: 'Change orientation',
			action: () =>
				toggleOrientationModalState({ open: true, isLoading: false }),
			icon: (
				<svg
					width='17px'
					style={{ marginRight: '14px' }}
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<rect
						x='0.75'
						y='2.34375'
						width='12.1299'
						height='19.9054'
						rx='1.30821'
						stroke='currentColor'
						strokeWidth='1.5'
					></rect>
					<path
						d='M0.59375 6.02539H13.126'
						stroke='currentColor'
						strokeWidth='1.5'
					></path>
					<path
						d='M0.59375 18.6133H13.126'
						stroke='currentColor'
						strokeWidth='1.5'
					></path>
					<path
						d='M19.0781 22.6348L19.0781 10.1025'
						stroke='currentColor'
						strokeWidth='1.5'
					></path>
					<path
						// eslint-disable-next-line max-len
						d='M16.0085 9.91797H20.994C22.1307 9.91797 23.0522 10.8395 23.0522 11.9762V20.392C23.0522 21.5287 22.1307 22.4502 20.994 22.4502H15.0938'
						stroke='currentColor'
						strokeWidth='1.5'
						strokeLinecap='round'
					></path>
					<path
						d='M15.7969 1C17.2635 1.02222 20.2369 2.05333 20.3969 6'
						stroke='currentColor'
						strokeWidth='1.5'
						strokeLinecap='round'
					></path>
					<path
						d='M18 4.54886L19.8801 6.27857C20.0913 6.47283 20.4271 6.42696 20.5784 6.18317L21.9335 4'
						stroke='currentColor'
						strokeWidth='1.5'
						strokeLinecap='round'
					></path>
				</svg>
			),
		});

		if(!facet?.isFluxPatched && canRemoveShading){
			Options.push({
				label: 'Remove shading',
				action: () => toggleShadingModalState({ value: true }),
				icon: (
					<svg width="17" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '14px' }}>
						<g clipPath="url(#clip0_6258_3)">
							<path d="M15.2478 2.6875C13.7705 1.62545 11.9583 1 10 1C5.02944 1 1 5.02944 1 10C1 12.8422 2.31746 15.3767 4.375 17.026M15.2478 2.6875C17.5202 4.32113 19 6.98775 19 10C19 14.9706 14.9706 19 10 19C7.87162 19 5.9158 18.2612 4.375 17.026M15.2478 2.6875L4.375 17.026" stroke="#4A525A" strokeWidth="1.58625"/>
							<path d="M10.0013 12.5722L9.99777 15.7879M11.3033 5.67611C11.2335 5.53168 11.1395 5.39853 11.0239 5.28294C10.7527 5.01174 10.3849 4.85938 10.0013 4.85938C9.6178 4.85938 9.24996 5.01174 8.97876 5.28294C8.70755 5.55415 8.55519 5.92198 8.55519 6.30552V6.31998C8.22004 6.27088 7.87788 6.32379 7.57322 6.47183C7.26855 6.61987 7.01551 6.85619 6.847 7.15003C6.6785 7.44387 6.60235 7.78163 6.62845 8.11934C6.65455 8.45706 6.7817 8.77911 6.99335 9.04356C6.65006 9.27425 6.39005 9.60921 6.2517 9.99899C6.11335 10.3888 6.104 10.8127 6.22502 11.2082C6.30174 11.4589 6.42826 11.6897 6.59538 11.8877M13.2958 7.47019C13.3643 7.67986 13.3914 7.90202 13.3742 8.12416C13.3481 8.46188 13.221 8.78393 13.0093 9.04838C13.3505 9.27985 13.6084 9.61456 13.7454 10.0034C13.8824 10.3922 13.8911 10.8147 13.7703 11.2089C13.6495 11.6031 13.4056 11.9481 13.0743 12.1935C12.743 12.4388 12.3418 12.5716 11.9295 12.5722H9.26156" stroke="#4A525A" strokeWidth="1.58625" strokeLinecap="round" strokeLinejoin="round"/>
						</g>
						<defs>
							<clipPath id="clip0_6258_3">
								<rect width="20" height="20" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				),
		
			});
		}
		if(facet?.isFluxPatched && canRemoveShading){
			Options.push({
				label: 'Undo remove shading',
				action: () => toggleUndoShadingModalState({ value: true}),
				icon: ( 
					<svg width="16" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '14px' }}>
						<g clipPath="url(#clip0_6258_6)">
							<path d="M16.1818 7.07139C16.1818 9.46665 14.2401 11.4084 11.8448 11.4084C10.8192 11.4084 9.87669 11.0524 9.13419 10.4572C8.14268 9.66234 7.50781 8.44101 7.50781 7.07139C7.50781 4.67613 9.44956 2.73438 11.8448 2.73438C12.7885 2.73438 13.6618 3.03578 14.3737 3.54756C15.4687 4.33479 16.1818 5.61982 16.1818 7.07139Z" stroke="#64686A" strokeWidth="1.41"/>
							<path d="M16.1818 7.07139C16.1818 9.46665 14.2401 11.4084 11.8448 11.4084C10.8192 11.4084 9.87669 11.0524 9.13419 10.4572C8.14268 9.66234 7.50781 8.44101 7.50781 7.07139C7.50781 4.67613 9.44956 2.73438 11.8448 2.73438C12.7885 2.73438 13.6618 3.03578 14.3737 3.54756C15.4687 4.33479 16.1818 5.61982 16.1818 7.07139Z" stroke="#4A525A" strokeWidth="1.41"/>
							<path d="M9.0432 1.69765C8.5965 1.25095 7.99065 1 7.35892 1C6.7272 1 6.12134 1.25095 5.67464 1.69765C5.22795 2.14435 4.97699 2.7502 4.97699 3.38193V3.40575C4.42498 3.32487 3.8614 3.41202 3.3596 3.65585C2.85779 3.89969 2.441 4.28893 2.16347 4.7729C1.88593 5.25688 1.76049 5.81319 1.80348 6.36944C1.84648 6.92569 2.0559 7.45613 2.40451 7.89171C1.83909 8.27168 1.41082 8.82338 1.18295 9.46538C0.955072 10.1074 0.939669 10.8056 1.13901 11.457C1.33835 12.1085 1.74187 12.6785 2.28999 13.083C2.83811 13.4876 3.50179 13.7051 4.18302 13.7036H10.5348C11.2138 13.7026 11.8747 13.484 12.4203 13.0799M7.35892 13.7036L7.35304 19.0003" stroke="#64686A" strokeWidth="1.41" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M9.0432 1.69765C8.5965 1.25095 7.99065 1 7.35892 1C6.7272 1 6.12134 1.25095 5.67464 1.69765C5.22795 2.14435 4.97699 2.7502 4.97699 3.38193V3.40575C4.42498 3.32487 3.8614 3.41202 3.3596 3.65585C2.85779 3.89969 2.441 4.28893 2.16347 4.7729C1.88593 5.25688 1.76049 5.81319 1.80348 6.36944C1.84648 6.92569 2.0559 7.45613 2.40451 7.89171C1.83909 8.27168 1.41082 8.82338 1.18295 9.46538C0.955072 10.1074 0.939669 10.8056 1.13901 11.457C1.33835 12.1085 1.74187 12.6785 2.28999 13.083C2.83811 13.4876 3.50179 13.7051 4.18302 13.7036H10.5348C11.2138 13.7026 11.8747 13.484 12.4203 13.0799M7.35892 13.7036L7.35304 19.0003" stroke="#4A525A" strokeWidth="1.41" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M9.67188 5.83561H12.8042C13.1237 5.83561 13.4301 5.958 13.656 6.17586C13.882 6.39372 14.0089 6.68921 14.0089 6.99731C14.0089 7.30541 13.882 7.60089 13.656 7.81875C13.4301 8.03661 13.1237 8.15901 12.8042 8.15901H10.6357M9.67188 5.83561L10.6357 4.90625M9.67188 5.83561L10.6357 6.76497" stroke="#64686A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M9.67188 5.83561H12.8042C13.1237 5.83561 13.4301 5.958 13.656 6.17586C13.882 6.39372 14.0089 6.68921 14.0089 6.99731C14.0089 7.30541 13.882 7.60089 13.656 7.81875C13.4301 8.03661 13.1237 8.15901 12.8042 8.15901H10.6357M9.67188 5.83561L10.6357 4.90625M9.67188 5.83561L10.6357 6.76497" stroke="#4A525A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
						</g>
						<defs>
							<clipPath id="clip0_6258_6">
								<rect width="17" height="20" fill="white"/>
							</clipPath>
						</defs>
					</svg>
				),
			});
		}
	}

	function handleOptionClick(option: Option) {
		closeDropdwon();
		dispatch(option.action());
	}

	function closeDropdwon() {
		dispatch(setDropdownState({ open: false, position: null }));
	}

	const overlayStyle = { background: 'rgba(0,0,0,0)' };

	return (
		<Popup
			contentStyle={{
				width: 'fit-content',
				borderRadius: '4px',
				left: position?.x,
				top: position?.y,
				position: 'fixed',
				background: '#000000a1',
				border: 'none',
				display: !position ? 'none' : 'grid',
				boxShadow: '1px 0 5.3px #110e0e40'
			}}
			open={open}
			closeOnDocumentClick={true}
			onClose={closeDropdwon}
			{...{overlayStyle}}
		>
			<DropdownDiv>
				<CloseBtn style={{ marginBottom: '10px' }}>
					<svg
						style={{ color: '#fff' }}
						onClick={() => {
							dispatch(setUnfinalizedFacet({ id: null }));
							closeDropdwon();
						}}
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						stroke='currentColor'
						strokeWidth='2'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M6 18L18 6M6 6l12 12'
						></path>
					</svg>
				</CloseBtn>
				{Options.map((option) => (
					<Button
						style={{
							paddingTop: '0.5em',
							paddingBottom: '0.5em',
							color: '#28373E',
							fontSize: '0.938rem',
							background: '#fff',
							borderColor: '#feb517',
							display: 'flex',
							alignItems: 'center',
							borderRadius: '27px',
							fontWeight: '600',
							justifyContent: 'start'
						}}
						key={option.label}
						disabled= {queriesRunningInBackGround}
						onClick={() => handleOptionClick(option)}
					>
						{option.icon}
						<span style={{ marginTop: '2px' }}>{option.label}</span>
					</Button>
				))}
			</DropdownDiv>
		</Popup>
	);
}
